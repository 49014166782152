/* You can add global styles to this file, and also import other style files */

/* Const: */
$color-font: white;
$color-1: #ff7900;
$color-2: #8f8f8f;
$color-3: #595959;

$font: 'Fira Code', monospace;

$font-size-very-small: 0.65rem;
$font-size-little: 0.8rem;
$font-size-normal: 1rem;
$font-size-normal-plus: 1.2rem;
$font-size-medium: 1.5rem;
$font-size-big: 2rem;
$font-size-very-big: 3rem;

$border: $color-3 solid 0.15rem;

$borderRadius: 0.2rem;

html {
    width: 100vw;
    height: 100vh;

    margin: 0;
    padding: 0;

    box-sizing: border-box;

    font-family: $font;
    color: $color-font;

    background-color: black;

    overflow: hidden;
}

body {
    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;

    box-sizing: border-box;

    overflow: hidden;
}

html, body {
    font-size: 1.65vh;
}

@media only screen and (max-width: 1050px), (max-height: 600px) and (-webkit-min-device-pixel-ratio: 1) {
}

.loading {
    position: relative;
    display: block;
    content: '';

    width: 100%;
    height: 100%;
    min-height: 1.7rem;
    min-width: 3rem;

    margin: 0.1rem;

    background-color: $color-1;

    animation: load 2s ease-in-out infinite alternate;

    @keyframes load {
        0% {transform: scaleX(1);
            transform-origin: right;}
        33% {transform: scaleX(0);
            transform-origin: left;}
        66% {transform: scaleX(1);
            transform-origin: left;}
        100% {transform: scaleX(0);
            transform-origin: right;}
    }
}

@for $i from 2 through 10 {
    .col-span-#{$i} {
        grid-column: span #{$i};
    }

    .row-span-#{$i} {
        grid-row: span #{$i};
    }

    .join-#{$i}-input {
        @for $j from 1 through 10 {
            :nth-child(#{$j}){
                border-radius: 0;
            }
        }

        :first-child {
            border-top-left-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
        }

        :last-child {
            border-top-right-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
        }

    }
}

.empty {
    content: '';
    min-height: 1rem;
}

.center {
    align-self: center;
    justify-self: center;
    text-align: center;
}

.border-b {
    border-bottom: $color-2 solid 0.15rem;
}

.border-r {
    border-right: $color-2 solid 0.15rem;
}

.color-1 {
    color: $color-1;
}

.bold {
    font-weight: bold;
}

.input-container {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-items: center;

    gap: 0.2rem;

    color: $color-1;

    .wrong {
        height: 0;
        order: 2;
        opacity: 0;

        transition: opacity .2s ease-in-out;

        &.active {
            height: 100%;
            order: 1;
            opacity: 1;
        }
    }
}